import { Box, Button, Drawer, IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import { useState } from "react";
import {getLocalStorageSettingValue, settings, userCanAccessSetting} from "settings";
import Options from "./options.component";

export default function Settings({ currentUser, onChange, onSubmit, inputs }) {
  const [open, setOpen] = useState(false);
  const [xPos, setXPos] = useState(localStorage.getItem("settings-button-x") || "right");
  const [yPos, setYPos] = useState(localStorage.getItem("settings-button-y") || "bottom");
  const position = { [xPos]: 20, [yPos]: 20 };

  function toggleDrawer() {
    setOpen(false);
  }

  function onDragEnd(e) {
    const yPercentage = (e.clientY / window.innerHeight) * 100;
    const xPercentage = (e.clientX / window.innerWidth) * 100;
    const x = xPercentage < 50 ? "left" : "right";
    const y = yPercentage < 50 ? "top" : "bottom";
    setXPos(x);
    setYPos(y);
    localStorage.setItem("settings-button-x", x);
    localStorage.setItem("settings-button-y", y);
  }

  return (
    (<Box sx={{ ...position, position: "fixed", zIndex: 10, transform: "translate(0,0)" }}>
      <IconButton
        color="primary"
        onClick={() => setOpen(true)}
        draggable
        onDragEnd={onDragEnd}
        size="large">
        <SettingsIcon />
      </IconButton>
      <Drawer anchor="right" open={open} onClose={toggleDrawer}>
        <Box sx={{ p: 2, minWidth: "20rem" }}>
          <form noValidate onSubmit={onSubmit}>
            <h2>Settings</h2>
            {settings.filter(s => userCanAccessSetting(currentUser, s)).map(setting => (
              <div key={setting.name}>
                <Options
                  setting={setting}
                  value={
                    inputs[setting.name] ??
                    getLocalStorageSettingValue(setting.name) ??
                    setting.default
                  }
                  onChange={onChange}
                />
              </div>
            ))}
            <Box sx={{ display: "flex", gap: 10 }}>
              <Button type="submit" variant="contained" color="primary" startIcon={<CheckIcon />}>
                Apply
              </Button>
              <Button type="button" onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Drawer>
    </Box>)
  );
}
