import { createTheme }               from '@mui/material/styles';
import { CSSProperties }             from '@mui/styles';
import ArboriaWoff                   from '../assets/fonts/arboria-medium.woff';
import ArboriaWoff2                  from '../assets/fonts/arboria-medium.woff2';

const arboria: CSSProperties = {
  fontFamily: 'Arboria',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local("Arboria"),
    url(${ArboriaWoff2}) format("woff2"),
    url(${ArboriaWoff}) format("woff")
  `
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
      light: '#f5f5f5'
    },
    secondary: { main: '#dc1839' },
    info: { main: '#094CB9' }
  },
  typography: {
    fontFamily: 'Arboria, sans-serif',
    h1: {
      fontWeight: 500,
      fontSize: '2.25rem'
    },
    h2: {
      fontWeight: 500,
      fontSize: '2rem'
    },
    h3: {
      fontWeight: 500,
      fontSize: '1.75rem'
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.5rem'
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.25rem'
    },
    h6: {
      fontWeight: 500,
      fontSize: '1rem'
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1.2rem',
      lineHeight: 1.4
    },
    subtitle2: { fontSize: '1rem' },
    body2: { fontSize: '0.875rem', opacity: 0.8 }
  },
  components: {
    MuiButtonBase: { defaultProps: { disableRipple: true, }, },
    MuiButton: {
      defaultProps: { variant: 'contained', },
      styleOverrides: {
        root: {
          appearance: 'none',
          textTransform: 'none',
          backgroundColor: 'transparent',
          color: 'var(--color-button, var(--color-primary))',
          borderRadius: '0.25rem',
          borderColor: 'var(--color-button, var(--color-primary))',
          transition: 'all 0.3s',
          height: '3rem',
          lineHeight: '3rem',
          minWidth: '160px',
          fontSize: '1.125rem',
          textAlign: 'center',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          padding: '0 1.5rem',
          verticalAlign: 'baseline',
          '&:hover': { background: 'var(--color-button-hover, var(--color-secondary))', },
        },
        outlined: {
          backgroundColor: 'transparent',
          borderWidth: '2px',
          borderStyle: 'solid',
          '&:hover': {
            borderWidth: '2px',
            backgroundColor: 'var(--color-button-hover, var(--color-secondary))',
            borderColor: 'var(--color-button-hover, var(--color-secondary))',
            color: 'var(--color-button-contrast, var(--color-primary-contrast))',
          },
        },
        contained: {
          backgroundColor: 'var(--color-button, var(--color-primary))',
          color: 'var(--color-button-contrast, var(--color-primary-contrast))',
          border: 0,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'var(--color-button-hover, var(--color-secondary))',
          },
        },
        sizeSmall: {
          height: '2rem',
          lineHeight: '1.75rem',
          fontSize: '0.875rem',
          minWidth: 'unset',
        }
      }
    },
    MuiTextField: { defaultProps: { variant: 'outlined', }, },
    MuiSelect: { defaultProps: { variant: 'outlined', }, },
    MuiCssBaseline: { styleOverrides: { '@font-face': [arboria], } },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          display: 'block',
          color: 'inherit',
          lineHeight: 'inherit'
        },
        asterisk: { color: 'red' }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--color-input-background)',
          borderRadius: '0.375rem'
        },
      }
    },
    MuiInputLabel: { styleOverrides: { filled: { '&.MuiInputLabel-shrink': { display: 'none' } } } },
    MuiDialog: { styleOverrides: { paper: { borderRadius: '0.5em' } } },
    MuiFormControl: { styleOverrides: { root: { display: 'flex' } } }
  }
});

export default theme;
