import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  InputLabel,
  Radio,
  RadioGroup
} from "@mui/material";

export default function Options({ setting, value, onChange }) {
  if (setting.type === "feature") {
    return (
      <Box sx={{ mb: 4 }}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">{setting.label}</FormLabel>
          <RadioGroup aria-label="gender" name={setting.name} value={value} onChange={onChange}>
            <FormControlLabel value="true" control={<Radio />} label="On" />
            <FormControlLabel value="false" control={<Radio />} label="Off" />
          </RadioGroup>
        </FormControl>
      </Box>
    );
  }

  return (
    <Box sx={{ mb: 4 }}>
      <InputLabel htmlFor={`setting-${setting.name}`}>{setting.label}</InputLabel>
      <Input
        id={`setting-${setting.name}`}
        name={setting.name}
        label={setting.label}
        onChange={onChange}
        value={value || ""}
        fullWidth
      />
    </Box>
  );
}
