import './index.css'
import { useMemo, useState } from 'react';
import { getEnv } from '../../lib/helpers/env';
import { Box } from '@mui/material';
import { Close, Report } from '@mui/icons-material';

export default function DevBanner() {
  const [isVisible, setIsVisible] = useState(getDefaultVisibility());

  const envName = useMemo(() => {
    switch (getEnv('REACT_APP_AWS_ENV')) {
      case undefined:
        return null;
      case 'development':
        return 'local';
      default:
        return getEnv('REACT_APP_AWS_ENV')
    }

  }, [getEnv('REACT_APP_AWS_ENV')])

  function toggleVisibility() {
    setIsVisible(!isVisible);
  }

  if (!isVisible || !envName) {
    return null
  }

  return (
    <Box className={`dev-banner dev-banner--${envName}`}>
      <Report className="dev-banner-icon" />
      <span className="dev-banner-message">You are in the <span className="dev-banner-name">{envName}</span> environment</span>
      <button className="dev-banner-close" title="Dismiss" onClick={toggleVisibility}><Close style={{ fontSize: '1rem' }}/></button>
    </Box>
  )
}

function getDefaultVisibility() {
  if (getEnv('REACT_APP_AWS_ENV') === 'production') {
    return false
  }

  if (localStorage.getItem('show-environment') === 'false') {
    return false
  }

  return true
}
