import axios, { AxiosError, AxiosResponse }   from 'axios';
import { getServerURL }                       from 'configureApi';
import apiConfig                              from 'res-api-cli/build/config';
import reportError, { ErrorTags }             from '../errorReporting';


const server = axios.create({ baseURL: getServerURL() })

export class NetworkError extends Error {
  constructor(message: string) {
    super(message)
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class CmsError extends NetworkError {
  code: number

  constructor(message: string, code: number) {
    super(message)
    this.code = code
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

/**
 * We allow auth to be handled by the current mechanism
 *
 * This appears to be packages/oso-spa/src/configureApp.js
 * using a global callback calling packages/oso-spa/src/lib/authData.js
 */
server.interceptors.request.use(
  (config) => {
    // @ts-expect-error apiConfig has not been set up well in old world code. We will re-write the auth config anyway
    config.headers.Authorization = apiConfig.headers.Authorization

    return config
  },
  error => Promise.reject(error)
)

const parseResponse = <R>(endpoint: string, response: AxiosResponse<R>): R => {

  if (response.data) {
    return response.data
  }

  const error = new CmsError('An unexpected issue occurred', 500)
  reportError(
    error,
    'CMS API Returned no data',
    {
      context: {
        status: response.status,
        url:    endpoint,
        tags:   `${ErrorTags.API}, ${ErrorTags.API_AXIOS}`
      }
    }
  );
  throw error;
}

const put = <IPayload, IResponse>(endpoint: string, payload: IPayload): Promise<IResponse> => server.put(endpoint, payload)
  .then((response: AxiosResponse<IResponse>) => parseResponse(endpoint, response))
  .catch((error: AxiosError) => {
    throw handleException(error)
  })

const get = <IResponse>(endpoint: string): Promise<IResponse> => server.get(endpoint)
  .then((response: AxiosResponse<IResponse>) => parseResponse(endpoint, response))
  .catch((error: AxiosError) => {
    throw handleException(error)
  })

const handleException = (error: AxiosError) => {
  // Is network issue
  if (!error.response) {
    return new NetworkError(error.message || 'Network error')
  }

  if (error.response.status === 500) {
    reportError(
      error,
      'CMS API 500 Error',
      {
        context: {
          tags: `${ErrorTags.API_AXIOS}, ${error.response.status}, ${error?.config?.method ?? 'no-http-method'}`,
          url: error?.config?.url ?? 'no-url-set',
          status: error.response.status,
          data:   error.response.data
        }
      }
    );
    return new CmsError('There was an error', error.response.status)
  }

  // Standard cms error
  const message = (error.response as AxiosResponse)?.data?.message;
  if (message) {
    return new CmsError(message, error.response.status)
  }

  return new CmsError(error.message, error.response.status)
}

export default {
  get,
  put
}
